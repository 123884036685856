<div id="home"></div>
<!-- Toolbar -->
<div class="toolbar" role="banner">
  <div class="spacer"></div>
  <a href="#home">
    <img
      width="40"
      alt="Home"
      src="../assets/HiltosLogo_white.svg"
    />
  </a>
  <span><a href="#home">Hiltos Chainlink</a></span>
  <div class="spacer" style="flex: 0 1 40px;"></div>
  <span><a href="#quick-start">Quick Start</a></span>
  <div class="spacer" style="flex: 0 1 40px;"></div>
  <span><a href="#snippets">Snippets</a></span>
  <div class="spacer" style="flex: 0 1 40px;"></div>
  <span><a href="#resources">Resources</a></span>
  <div class="spacer" style="flex: 0 1 40px;"></div>
  <span><a href="#links">Links</a></span>
  <div class="spacer"></div>

</div>

<div class="content" role="main">

  <!-- Introduction -->
  <h1>Hiltos Chainlink Oracle Services</h1>
  <p>Power up your Smart Contracts with off-chain data, events, and payments.</p>
  <p style="margin-bottom: 16px;">We provide provable inputs and outputs including payment transactions, data from any API, and secure <strong>random numbers</strong>.</p>

  <!-- Quick Start -->
  <h2 id="quick-start">Quick Start</h2>
  <p>Getting started is easy. Follow the steps below to integrate the Hiltos Chainlink Oracle into your smart contracts.</p>

  <h4>Step 1. Import the Chainlink Client</h4>
  <div class="terminalBox">
    <div class="terminal">
      <pre>
        <code class="prettyprint">import "@chainlink/contracts/src/v0.6/ChainlinkClient.sol";</code>
      </pre>
    </div>
  </div>

  <input type="hidden" #qselection>

  <div class="card-container">
    <div class="card terminalSwitch" (click)="qselection.value = 'mainnet';" tabindex="0">
      <span>Mainnet</span>
    </div>
    <div class="card terminalSwitch" (click)="qselection.value = 'ropsten';" tabindex="0">
      <span>Ropsten</span>
    </div>
  </div>

  <div class="terminalBox" [ngSwitch]="qselection.value">

    <div *ngSwitchDefault>
      <div class="terminal">
        <h4>Mainnet Connection Details</h4>
        <pre>
          <code class="prettyprint linenums">Contract Details</code>
        </pre>
      </div>
    </div>

    <div *ngSwitchCase="'ropsten'">
      <div class="terminal">
        <h4>Ropsten Connection Details</h4>
        <pre>
          <code class="prettyprint linenums">Contract Details</code>
        </pre>
      </div>
    </div>

  </div>


  <!-- Snippets -->
  <h2 id="snippets">Snippets</h2>
  <p>What do you want to do next with your smart contract?</p>

  <input type="hidden" #selection>

  <div class="card-container">
    <div class="card card-small terminalSwitch" (click)="selection.value = 'rng';" tabindex="0">
        <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>

      <span>Get Random Numbers</span>
    </div>

    <div class="card card-small terminalSwitch" (click)="selection.value = 'price';" tabindex="0">
        <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>

      <span>Get ETH/USD Price</span>
    </div>

    <div class="card card-small terminalSwitch" (click)="selection.value = 'get-api';" tabindex="0">
        <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>

      <span>GET from an API</span>
    </div>

    <div class="card card-small terminalSwitch" (click)="selection.value = 'post-to-reddit';" tabindex="0">
      <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>

      <span>Post to Reddit</span>
    </div>

    <div class="card card-small terminalSwitch" (click)="selection.value = 'payment';" tabindex="0">
      <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>

      <span>Payout from PayPal</span>
    </div>

    <div class="card card-small terminalSwitch" (click)="selection.value = 'stocks';" tabindex="0">
      <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>

      <span>Get Stock Data</span>
    </div>
  </div>

  <div class="terminalBox" [ngSwitch]="selection.value">



    <div *ngSwitchDefault>
      <div class="terminal">
        <h4>Getting Secure and Provable Random Numbers</h4>
        <pre>
          <code class="prettyprint linenums">class Rng {{'{'}}
          public:
            // Voila
            static const string VOILA = "Voila";
          
            // will not interfere with embedded tags.
            {{'}'}}</code>
        </pre>
      </div>
    </div>

    <div *ngSwitchCase="'price'">
      <div class="terminal">
        <h4>Getting the Current ETH/USD Price</h4>
        <pre>
          <code class="prettyprint linenums">class Price {{'{'}}
          public:
            // Voila
            static const string VOILA = "Voila";
          
            // will not interfere with embedded tags.
            {{'}'}}</code>
        </pre>
      </div>
    </div>

    <div *ngSwitchCase="'get-api'">
      <div class="terminal">
        <h4>HTTP GET from an API</h4>
        <pre>
          <code class="prettyprint linenums">class ApiGet {{'{'}}
          public:
            // Voila
            static const string VOILA = "Voila";
          
            // will not interfere with embedded tags.
            {{'}'}}</code>
        </pre>
      </div>
    </div>

    <div *ngSwitchCase="'post-to-reddit'">
      <div class="terminal">
        <h4>Make a Post on Reddit</h4>
        <pre>
          <code class="prettyprint linenums">class PostToReddit {{'{'}}
          public:
            // Voila
            static const string VOILA = "Voila";
          
            // will not interfere with embedded tags.
            {{'}'}}</code>
        </pre>
      </div>
    </div>

    <div *ngSwitchCase="'payment'">
      <div class="terminal">
        <h4>Send an Off-Chain Payment from PayPal</h4>
        <pre>
          <code class="prettyprint linenums">class Payment {{'{'}}
          public:
            // Voila
            static const string VOILA = "Voila";
          
            // will not interfere with embedded tags.
            {{'}'}}</code>
        </pre>
      </div>
    </div>

    <div *ngSwitchCase="'stocks'">
      <div class="terminal">
        <h4>Getting Real-Time Stock Data</h4>
        <pre>
          <code class="prettyprint linenums">class Stocks {{'{'}}
          public:
            // Voila
            static const string VOILA = "Voila";
          
            // will not interfere with embedded tags.
            {{'}'}}</code>
        </pre>
      </div>
    </div>



  </div>

  <!-- Resources -->
  <h2 id="resources">Resources</h2>
  <p>Here are some links to help you get started:</p>

  <div class="card-container">
    <a class="card" target="_blank" rel="noopener" href="#">
      <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z"/></svg>

      <span>Hiltos Oracle Docs</span>

      <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>    </a>

    <a class="card" target="_blank" rel="noopener" href="https://docs.chain.link/docs">
      <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"/></svg>

      <span>Chainlink Docs</span>

      <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>
    </a>

    <a class="card" target="_blank" rel="noopener" href="https://solidity.readthedocs.io/en/latest/">
      <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z"/></svg>

      <span>Solidity Docs</span>

      <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>
    </a>

  </div>

  <!-- Links -->
  <div id="links" class="card-container">
    <a class="circle-link" title="Hiltos" href="#" target="_blank" rel="noopener">
      <img
        width="22"
        alt="Home"
        src="../assets/HiltosLogo_red.svg"
      />
    </a>

    <a class="circle-link" title="Chainlink" href="https://chain.link/" target="_blank" rel="noopener">
      <img
        width="24"
        alt="Home"
        src="../assets/ChainlinkLogo.png"
      />
    </a>

    <a class="circle-link" title="Hiltos" href="#" target="_blank" rel="noopener">
      <img
        width="22"
        alt="Home"
        src="../assets/HiltosLogo_red.svg"
      />
    </a>

    <a class="circle-link" title="Hiltos" href="#" target="_blank" rel="noopener">
      <img
        width="22"
        alt="Home"
        src="../assets/HiltosLogo_red.svg"
      />
    </a>

    <a class="circle-link" title="Hiltos" href="#" target="_blank" rel="noopener">
      <img
        width="22"
        alt="Home"
        src="../assets/HiltosLogo_red.svg"
      />
    </a>

    <a class="circle-link" title="Hiltos" href="#" target="_blank" rel="noopener">
      <img
        width="22"
        alt="Home"
        src="../assets/HiltosLogo_red.svg"
      />
    </a>
  </div>

  <!-- Footer -->
  <footer>
    <p>Need some help?&nbsp;<a href="#" target="_blank" rel="noopener"> Get in touch!</a></p>
    <p>© 2020 Hiltos. All copyrights belong to their respective owners.</p>
  </footer>

  <svg id="clouds" alt="Gray Clouds Background" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
    <path id="Path_39" data-name="Path 39" d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z" transform="translate(142.69 -634.312)" fill="#eee"/>
  </svg>

</div>
<a href="#home" id="homeLink" title="Go to top">Top</a>

<router-outlet></router-outlet>